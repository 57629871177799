"use client";
import React from "react";

import { QueryClientProvider } from "react-query";
import { reactQueryClient } from "~/lib/apiClient";

export function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <QueryClientProvider client={reactQueryClient}>
      {children}
    </QueryClientProvider>
  );
}
