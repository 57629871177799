"use client";
import { type ReactNode } from "react";
import { CacheProvider } from "@emotion/react";
import { Notifications } from "@mantine/notifications";

import { MantineProvider } from "@mantine/core";
import { useGluedEmotionCache } from "~/lib/emotionNextJsGlue";

export default function EmotionProvider({ children }: { children: ReactNode }) {
  const cache = useGluedEmotionCache();
  return (
    <CacheProvider value={cache}>
      {/* You can wrap ColorSchemeProvider right here but skipping that for brevity ;) */}
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS={false}
        emotionCache={cache}
      >
        <Notifications className="rounded shadow-2xl" />
        {children}
      </MantineProvider>
    </CacheProvider>
  );
}
