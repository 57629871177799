import { QueryClient } from "react-query";
import axios from "axios";

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3, // the default of 4 seems just too much
    },
  },
});

// prefix with cooper as we may have other 3rd party api clients in the future
export const apiV1Client = axios.create({
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export function unwrapData<T>(response: { data: T }) {
  return response.data;
}
