"use client";

import { useSearchParams, useRouter } from "next/navigation";
import { useLocalStorage } from "react-use";
import { useEffect } from "react";

type Props = {
  hasSession: boolean;
};
export function AfterAuthRedirectDetector({ hasSession }: Props) {
  const [, setValue] = useLocalStorage("afterSigninRedirect", "");

  const router = useRouter();
  const searchParams = useSearchParams();
  const redirectAfterSigningUrl = searchParams.get("afterSigninRedirect");

  useEffect(() => {
    setValue(redirectAfterSigningUrl ?? "");
    // NOTE: don't add `redirectAfterSigningUrl` to the dependencies array
    // the setValue needs to happen only on page open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  if (hasSession) {
    if (redirectAfterSigningUrl) {
      void router.replace(redirectAfterSigningUrl);
    } else {
      void router.replace("/");
    }
  }

  return null;
}
